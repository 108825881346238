import styled, { css } from 'styled-components';
import { generateScalingQueries, generateScales } from '@helpers/generate-media-queries';
import Button from '@components/button';
import { LargeButton } from '@styles/button.styled';

export const SubmitContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
    }
  `}
`;

export const HubSpotForm = styled.div`
  ${({ theme }) => css`
    .hs-form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 1.5rem;
      column-gap: 1.5rem;
      width: 100%;

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .hs_submit {
      width: 100%;
    }

    .actions {
      width: 100%;
    }

    .hs-button {
      ${LargeButton}
      width: 100%;
      border-color: ${theme.colors.darkPurple};
      background: ${theme.colors.darkPurple};
      color: ${theme.colors.white};

      :hover {
        color: ${theme.colors.darkPurple};
        background-color: ${theme.colors.white};
        border-color: ${theme.colors.white};
      }

      :focus {
        outline: 4px solid ${theme.colors.white}44;
      }
    }

    .hs-form-field {
      display: flex;
      flex-direction: column;
      row-gap: 0.0625rem;

      input {
        border-radius: 0.125rem;
        ${generateScales(
          'font-size',
          theme.inputSize.lg.mobile.fontSize,
          theme.inputSize.lg.desktop.fontSize,
          theme
        )}
        ${generateScales('padding-left', '0.5rem', '0.75rem', theme)}
        ${generateScales('padding-right', '0.5rem', '0.75rem', theme)}
        ${generateScales('height', '2.75rem', '3rem', theme)}

        border: 1px solid ${theme.colors.secondary};
        background-color: ${theme.colors.white};
        color: ${theme.colors.darkPurple};
        outline: none;
        font-family: ${theme.fontFamily.text};
        transition: all ${theme.transitions.normal};
        border-radius: 0.125rem;
        width: 100%;

        ::placeholder {
          color: ${theme.colors.dark}99;
        }

        :focus {
          border-color: ${theme.colors.dark};
          outline: 4px solid ${theme.colors.secondary}22;
        }
      }

      label {
        font-weight: 400;
        font-family: ${theme.fontFamily.text};
        font-size: ${theme.fontSize.small.desktop};
        line-height: ${theme.lineHeights.xs};
        color: ${theme.colors.darkPurple};
        margin: 0 0 ${theme.spacing.xs} 0;
        ${generateScales(
          'font-size',
          theme.fontSize.small.mobile,
          theme.fontSize.small.desktop,
          theme
        )}
        ${generateScalingQueries('margin-bottom', theme.spacing.xs, theme)};

        &.hs-error-msg {
          font-family: ${theme.fontFamily.additional};
          color: ${theme.colors.tertiary};
          font-size: ${theme.fontSize.extraSmall.desktop};
        }
      }
    }
  `}
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const StyledFormContainer = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    ${generateScales('border-radius', '1rem', '2rem', theme)}
    background-color: ${theme.backgroundColors.light};
    ${generateScales('padding-left', '1rem', '3rem', theme)}
    ${generateScales('padding-right', '1rem', '3rem', theme)}
    ${generateScales('padding-top', '2rem', '3rem', theme)}
    ${generateScales('padding-bottom', '2rem', '3rem', theme)}
    justify-content: center;
    align-items: center;
    min-width: 44rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      min-width: 36rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      min-width: unset;
    }
  `}
`;

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${generateScales('row-gap', '1.5rem', '2.5rem', theme)}
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      align-items: center;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      align-items: flex-start;
    }
  `}
`;

export const FormRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    ${generateScales('column-gap', '1rem', '1.5rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      row-gap: 1rem;
    }
  `}
`;

export const Fields = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${generateScales('row-gap', '1rem', '1.5rem', theme)}
    width: 100%;
  `}
`;

export const ConfirmationWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    row-gap: 0.25rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${theme.backgroundColors.white};
    padding: 2rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      padding: 2rem 1.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 2rem 1rem;
    }
  `}
`;

export const NewsletterContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    ${generateScales('column-gap', '2rem', '4rem', theme)}
    width: 100%;
    align-self: center;
    margin: 0 auto;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      ${generateScales('row-gap', '3rem', '4rem', theme)}
    }
  `}
`;
