import React, { useState, useRef, useEffect } from 'react';
import getLinkConfig from '@helpers/get-link-config';
import Text from '@components/text';
import LinkComponent from '@components/link';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import ReCAPTCHA from 'react-google-recaptcha';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultTheme } from '@styles/theme.styled';
import InputGroup from '@components/input-group';
import {
  StyledFormContainer,
  HubSpotForm,
  FormRow,
  TitleContainer,
  Fields,
  ConfirmationWrapper,
  NewsletterContainer,
  SubmitContainer,
  StyledButton,
} from './enclosed-newsletter.styled';

declare global {
  interface Window {
    hbspt: {
      forms: {
        create: (config: { portalId: string; formId: string; target: string }) => void;
      };
    };
  }
}

export interface LinkProps {
  url?: string;
  uid?: string;
  target?: string;
}

export interface EnclosedNewsletterFormProps {
  title?: string;
  linkLabel?: string;
  link?: LinkProps;
  description?: RichTextBlock[];
  buttonLabel: string;
  confirmationTitle?: string;
  confirmationMessage?: RichTextBlock[];
  backgroundColor: keyof typeof defaultTheme.backgroundColors;
  formId?: string;
  embedCode?: string;
}

const EnclosedNewsletterForm = ({
  linkLabel,
  link,
  buttonLabel,
  confirmationTitle,
  confirmationMessage,
  title,
  description,
  backgroundColor,
  formId,
  embedCode,
}: EnclosedNewsletterFormProps): JSX.Element => {
  const linkConfig = getLinkConfig(link);

  // captcha
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [disabledState, setDisabledState] = React.useState(true);
  const [captchaResponse, stCaptchaResponse] = useState('');
  const sitekey = '6Le4oVoqAAAAAID4h2TaX9kEyN09BiFwN5PLGDYT';

  // hubspot form
  const portalId = '39489460';
  const form: string = formId || 'de32ea1f-d85b-426c-a3e2-e9786612e88c';
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${form}`;

  // get current page info
  const currentURL = typeof window !== 'undefined' ? window.location.href : 'Unknown URL';
  const pageTitle = typeof document !== 'undefined' ? document.title : 'Unknown title';
  const cleanPageTitle = pageTitle.replace(/TripShot \| /i, '');

  // states
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [displayConfirmation, setDisplayConfirmation] = useState(false);

  const setExpired = () => {
    stCaptchaResponse('');
    setDisabledState(true);
  };

  const setCaptchaValue = () => {
    const recaptchaValue = JSON.stringify(new Date().getTime());
    stCaptchaResponse(recaptchaValue);
    setDisabledState(false);
  };

  const submitToHubspot = () => {
    return new Promise((resolve, reject) => {
      const date = Date.now();
      const data = {
        submittedAt: date,
        fields: [
          {
            name: 'firstname',
            value: firstName,
          },
          {
            name: 'lastname',
            value: lastName,
          },
          {
            name: 'email',
            value: email,
          },
          {
            name: 'company',
            value: companyName,
          },
        ],
        context: {
          pageUri: currentURL,
          pageName: cleanPageTitle,
        },
      };
      const final_data = JSON.stringify(data);
      const xhr = new XMLHttpRequest();

      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else if (xhr.status === 400) {
            reject(new Error(xhr.statusText));
          } else if (xhr.status === 403) {
            reject(new Error(xhr.statusText));
          } else {
            reject(new Error(xhr.statusText));
          }
        }
      };
      xhr.send(final_data);
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitted(true);
    if (!firstName || !lastName || !companyName || !email || disabledState) {
      e.preventDefault();
    } else {
      e.preventDefault();
      submitToHubspot()
        .then(() => setDisplayConfirmation(true))
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    if (!embedCode) return;

    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId,
          formId: formId || '',
          target: `#hubspot-form-${formId || ''}`,
        });
      }
    });
  }, [embedCode]);

  return (
    <Section backgroundColor={backgroundColor}>
      <Wrapper>
        <NewsletterContainer>
          <TitleContainer>
            {title && (
              <Text
                tag="h3"
                fontColor="white"
                type="title"
                fontSize="heading3"
                stringText={title}
                margin="none"
              />
            )}
            {linkConfig.url !== '' && (
              <LinkComponent
                type="button"
                title={linkLabel || 'Schedule a Demo'}
                url={linkConfig.url}
                isExternal={linkConfig.isExternal}
                target={link?.target || '_self'}
              />
            )}
          </TitleContainer>
          <StyledFormContainer>
            {description && (
              <Text
                type="subheading"
                fontSize="heading6"
                richText={description}
                fontColor="primary"
                margin="none"
              />
            )}
            {embedCode ? (
              <HubSpotForm id={`hubspot-form-${form}`} />
            ) : (
              <form onSubmit={handleSubmit} id={`enclosed-form-${form}`}>
                <Fields>
                  <input
                    type="hidden"
                    name="captcha_settings"
                    value={`{"keyname":"Cometv2","fallback":"true","orgId":"00Df4000001eE2k","ts":"${captchaResponse}"}`}
                  />
                  <FormRow>
                    <InputGroup
                      isInvalid={isSubmitted && firstName === ''}
                      disabled={displayConfirmation}
                      placeholder="Your First Name"
                      name="firstname"
                      label="First Name"
                      value={firstName}
                      helpText="Please enter a first name"
                      onChange={setFirstName}
                    />
                    <InputGroup
                      isInvalid={isSubmitted && lastName === ''}
                      disabled={displayConfirmation}
                      placeholder="Your Last Name"
                      name="lastname"
                      label="Last Name"
                      value={lastName}
                      helpText="Please enter a last name"
                      onChange={setLastName}
                    />
                  </FormRow>
                  <FormRow>
                    <InputGroup
                      isInvalid={isSubmitted && email === ''}
                      disabled={displayConfirmation}
                      placeholder="Your Email Address"
                      name="email"
                      label="Email"
                      value={email}
                      helpText="Please enter a email address"
                      onChange={setEmail}
                    />
                    <InputGroup
                      isInvalid={isSubmitted && companyName === ''}
                      disabled={displayConfirmation}
                      placeholder="Your Company Name"
                      name="company"
                      label="Company"
                      value={companyName}
                      helpText="Please enter a company name"
                      onChange={setCompanyName}
                    />
                  </FormRow>
                </Fields>
                <SubmitContainer>
                  {/* @ts-expect-error Server Component */}
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={sitekey}
                    onChange={setCaptchaValue}
                    onExpired={setExpired}
                  />
                  <StyledButton
                    type="submit"
                    label={buttonLabel}
                    variant="dark"
                    disabled={disabledState || displayConfirmation}
                  />
                </SubmitContainer>
              </form>
            )}
          </StyledFormContainer>
          {displayConfirmation && (
            <ConfirmationWrapper>
              <Text
                type="title"
                tag="h1"
                fontSize="heading3"
                fontColor="primary"
                stringText={confirmationTitle}
              />
              <Text
                type="description"
                fontColor="primary"
                richText={confirmationMessage}
                fontSize="large"
              />
            </ConfirmationWrapper>
          )}
        </NewsletterContainer>
      </Wrapper>
    </Section>
  );
};

export default EnclosedNewsletterForm;
