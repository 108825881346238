import React from 'react';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import CallToAction from '@components/call-to-action';
import PageHero from '@components/page-hero';
import { ImageProps } from '@components/image';
import TextImage from '@components/text-and-image';
import Listing from '@components/listing';
import Showcase from '@components/showcase';
import EnclosedNewsletterForm from '@components/enclosed-newsletter';
import StatBlocks from '@components/stat-blocks';
import Tout from '@components/tout';
import CardTouts from '@components/card-touts';
import Solutions from '@components/solutions';
import TransitTypes from '@components/transit-types';
import FeatureCase from '@components/feature-case';
import Feedbacks from '@components/feedbacks';
import Wrapper from '@components/wrapper.styled';
import { RichTextContainer } from '@components/paragraphs/paragraphs.shared.styled';
import SecurityLabels from '@components/security-labels';
import ImageSlice from '@components/image-slice';
import LastPublicationDate from '@components/last-updated-date';
import ToutsInRow from '@components/touts-in-row';
import TextSection from '@components/text-section';
import ImageBox from '@components/image-box';
import PartnerAdvantage from '@components/partner-advantage';
import { defaultTheme } from '@styles/theme.styled';
import Partners from '@components/partners';
import Spectrum from '@components/spectrum';
import MasterDispatch from '@components/master-dispatch';
import Graphics from '@components/graphics';
import Experiences, { ContentType } from '@components/experiences';
import MeetTheLeaders from '@components/meet-the-leaders';
import Mission from '@components/mission';
import Dashboard from '@components/dashboard';
import Relationships from '@components/relationships';
import Promotions from '@components/promotions';
import ContactFormSection from '@components/contact-form-section';
import Patterns from '@components/patterns';
import Carousel from '@components/carousel';
import Positions from '@components/positions';
import AppDownload from '@components/app-download';
import Section from '@components/section';
import NewsletterFormSection from '@components/newsletter-form';
import ConfirmationMessage from '@components/confirmation-message';
import ResourcePosts from '@components/resource-posts';
import { AnchorType } from '@components/anchor-nav';
import Comparison, { ColumnProps, RowTitlesProps } from '@components/comparison';
import CTANewsletter from '@components/cta-newsletter';
import TabbedContent from '@components/tabbed-content';
import { Item } from './item-props.type';

export interface PrimaryProps {
  anchor_link?: string;
  top_anchor_link?: boolean;
  badge?: string;
  eyebrow?: {
    text: string;
  };
  title?: {
    text: string;
  };
  positions_title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  top_text?: {
    richText: RichTextBlock[];
  };
  right_text?: {
    richText: RichTextBlock[];
  };
  left_text?: {
    richText: RichTextBlock[];
  };
  bottom_text?: {
    richText: RichTextBlock[];
  };
  subtitle?: {
    richText: RichTextBlock[];
    text?: string;
  };
  subheading?: {
    richText: RichTextBlock[];
    text: string;
  };
  link?: {
    url?: string;
    uid?: string;
    target?: string;
  };
  link_label?: string;
  image?: ImageProps;
  size?: string;
  confirmation_title?: {
    text: string;
  };
  confirmation_message: {
    richText?: RichTextBlock[];
  };
  rich_text: {
    richText?: RichTextBlock[];
  };
  legal_text: {
    richText?: RichTextBlock[];
  };
  items_in_row?: string;
  align?: string;
  layout_type?: string;
  items_layout_type?: string;
  form_id?: string;
  embed_code?: string;
  button_label: string;
  background_alignment?: string;
  outer_background_color?: keyof typeof defaultTheme.backgroundColors;
  background_color?: keyof typeof defaultTheme.backgroundColors;
  background_image?: ImageProps;
  left_align_content?: boolean;
  display_border?: boolean;
  is_shifted?: boolean;
  ticker?: boolean;
  slides_to_show?: string;
  number_in_rows?: string;
  content_1?: ContentType;
  content_2?: ContentType;
  content_3?: ContentType;
  is_enclosed: boolean;
  is_thumbnail?: boolean;
  is_border_bottom?: boolean;
  font_size?: keyof typeof defaultTheme.fontSize;
  font_color?: 'dark' | 'white';
  campaign_id: string;
  notification_message?: {
    text: string;
  };
  notification_icon?: ImageProps;
  notification_url?: {
    url?: string;
  };
  is_notification_dismissible?: boolean;
  table_content: {
    document?: {
      data: {
        title: {
          text: string;
        };
        description: {
          richText: RichTextBlock[];
        };
        row_titles: RowTitlesProps[];
        body: ColumnProps[];
      };
    };
  };
}

export interface ModuleProps {
  slice_type: string;
  primary: PrimaryProps;
  items: Item[];
}

const renderModule = (
  { slice_type, primary, items }: ModuleProps,
  last_publication_date: string,
  secondary_nav: AnchorType[]
): JSX.Element | null => {
  const itemsPerRow = primary?.items_in_row ? +primary.items_in_row : 2;

  switch (slice_type) {
    case 'page_hero':
      return (
        <PageHero
          badge={primary.badge}
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          subheading={primary.subheading?.richText}
          subtitle={primary.subtitle?.text}
          image={primary.image}
          linkLabel={primary.link_label}
          link={primary.link}
          size={primary.size}
          outerbackgroundColor={primary.outer_background_color}
          backgroundColor={primary.background_color}
          left_align_content={primary.left_align_content}
          backgroundImage={primary.background_image}
          isShifted={primary.is_shifted}
          notificationMessage={primary.notification_message?.text}
          notificationIcon={primary.notification_icon}
          notificationUrl={primary.notification_url}
          isNotificationDismissible={primary.is_notification_dismissible}
          items={items}
          secondaryNav={secondary_nav}
        />
      );
    case 'app_download':
      return (
        <AppDownload
          description={primary.description?.richText}
          items={items}
          backgroundColor={primary.background_color}
        />
      );
    case 'text':
      return (
        <TextSection
          title={primary.title?.text}
          description={primary.description?.richText}
          align={primary.align}
          backgroundColor={primary.background_color}
        />
      );
    case 'showcase':
      return (
        <Showcase
          title={primary.title?.text}
          description={primary.description?.richText}
          backgroundColor={primary.background_color}
          items={items}
          link={primary.link}
          linkLabel={primary.link_label}
        />
      );
    case 'experiences':
      return (
        <Experiences
          title={primary.title?.text}
          description={primary.description?.richText}
          backgroundColor={primary.background_color}
          content={[primary.content_1, primary.content_2, primary.content_3]}
        />
      );
    case 'call_to_action':
      return (
        <CallToAction
          title={primary.title?.text}
          subheading={primary.subheading?.richText}
          buttonLabel={primary.link_label}
          link={primary.link?.url}
        />
      );
    case 'patterns':
      return (
        <Patterns
          title={primary.title?.text}
          items={items}
          link={primary.link}
          displayBorder={primary.display_border}
          backgroundAlignment={primary.background_alignment}
          outerbackgroundColor={primary.outer_background_color}
          backgroundColor={primary.background_color}
          linkLabel={primary.link_label}
        />
      );
    case 'positions':
      return (
        <Positions
          title={primary.title?.text}
          subtitle={primary.subtitle?.text}
          items={items}
          linkLabel={primary.link_label}
        />
      );
    case 'feature_case':
      return <FeatureCase items={items} />;
    case 'master_dispatch':
      return (
        <MasterDispatch
          topAnchorLink={primary.top_anchor_link}
          anchorLink={primary.anchor_link}
          image={primary.image}
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          description={primary.description?.richText}
          align={primary.align}
          layoutType={primary.layout_type}
          itemsLayoutType={primary.items_layout_type}
          displayBorder={primary.display_border}
          backgroundAlignment={primary.background_alignment}
          outerbackgroundColor={primary.outer_background_color}
          backgroundColor={primary.background_color}
          is_thumbnail={primary.is_thumbnail}
          items={items}
          link={primary.link}
          linkLabel={primary.link_label}
          isBorderBottom={primary.is_border_bottom}
          fontSize={primary.font_size}
          fontColor={primary.font_color}
          itemsPerRow={itemsPerRow}
        />
      );
    case 'text_and_image':
      return (
        <TextImage
          title={primary.title?.text}
          subheading={primary.subheading?.richText}
          image={primary.image}
          backgroundImage={primary.background_image}
          displayBorder={primary.display_border}
          backgroundAlignment={primary.background_alignment}
          outerbackgroundColor={primary.outer_background_color}
          backgroundColor={primary.background_color}
          align={primary.align}
        />
      );
    case 'transit_types':
      return <TransitTypes generalTitle={primary.title?.text} items={items} />;
    case 'image_slice':
      return <ImageSlice image={primary.image} layoutType={primary.layout_type} />;
    case 'listing':
      return <Listing title={primary.title?.text} items={items} />;
    case 'feedbacks':
      return (
        <Feedbacks
          title={primary.title?.text}
          displayBorder={primary.display_border}
          backgroundAlignment={primary.background_alignment}
          outerbackgroundColor={primary.outer_background_color}
          backgroundColor={primary.background_color}
          items={items}
        />
      );
    case 'security_labels':
      return (
        <SecurityLabels
          title={primary.title?.text}
          description={primary.description?.richText}
          align={primary.align}
          displayBorder={primary.display_border}
          backgroundAlignment={primary.background_alignment}
          outerbackgroundColor={primary.outer_background_color}
          backgroundColor={primary.background_color}
          itemsPerRow={itemsPerRow}
          ticker={primary.ticker}
          items={items}
        />
      );
    case 'rich_text':
      return (
        <Section backgroundColor="white">
          <Wrapper>
            <RichTextContainer>
              <RichText render={primary.rich_text?.richText || []} />
            </RichTextContainer>
          </Wrapper>
        </Section>
      );
    case 'tout':
      return (
        <Tout
          title={primary.title?.text}
          subheading={primary.subheading?.richText}
          displayBorder={primary.display_border}
          backgroundAlignment={primary.background_alignment}
          outerbackgroundColor={primary.outer_background_color}
          backgroundColor={primary.background_color}
          image={primary.image}
          link={primary.link}
          linkLabel={primary.link_label}
          layoutType={primary.layout_type}
        />
      );
    case 'last_updated_date':
      return (
        <LastPublicationDate align={primary.align} lastPublicationDate={last_publication_date} />
      );
    case 'touts_in_row':
      return <ToutsInRow title={primary.title?.text} items={items} />;
    case 'partners':
      return (
        <Partners
          title={primary.title?.text}
          description={primary.description?.richText}
          backgroundColor={primary.background_color}
          items={items}
          slidesToShow={+(primary.slides_to_show || '4')}
          numberInRows={+(primary.number_in_rows || '2')}
        />
      );
    case 'relationships':
      return (
        <Relationships
          title={primary.title?.text}
          description={primary.description?.richText}
          displayBorder={primary.display_border}
          backgroundAlignment={primary.background_alignment}
          outerbackgroundColor={primary.outer_background_color}
          backgroundColor={primary.background_color}
          isEnclosed={primary.is_enclosed}
          items={items}
        />
      );
    case 'cardtouts':
      return (
        <CardTouts
          title={primary.title?.text}
          itemsPerRow={itemsPerRow}
          subheading={primary.subheading?.richText}
          items={items}
          displayBorder={primary.display_border}
          backgroundAlignment={primary.background_alignment}
          outerbackgroundColor={primary.outer_background_color}
          backgroundColor={primary.background_color}
        />
      );
    case 'partner_advantage':
      return (
        <PartnerAdvantage
          backgroundColor={primary.background_color}
          title={primary.title?.text}
          description={primary.description?.richText}
          items={items}
        />
      );
    case 'spectrum':
      return <Spectrum title={primary.title?.text} items={items} />;
    case 'graphics':
      return (
        <Graphics
          title={primary.title?.text}
          description={primary.description?.richText}
          topText={primary.top_text?.richText}
          rightText={primary.right_text?.richText}
          leftText={primary.left_text?.richText}
          bottomText={primary.bottom_text?.richText}
          image={primary.image}
        />
      );
    case 'meet_the_leaders': {
      const formattedItems = items.map((item: Item) => ({
        isLinked: item.enable_bio_page,
        name: item.bio?.document?.data?.name,
        job_title: item.bio?.document?.data?.job_title,
        photo: item.bio?.document?.data?.photo,
        description: item.bio?.document?.data?.description,
      }));

      const hasItems: boolean =
        formattedItems[0].name !== undefined &&
        formattedItems[0].job_title !== undefined &&
        formattedItems[0].photo !== undefined;

      if (!hasItems) {
        return null;
      }

      return (
        <MeetTheLeaders
          items={formattedItems}
          title={primary.title?.text}
          positions_title={primary.positions_title?.text}
          itemsPerRow={itemsPerRow}
        />
      );
    }
    case 'imagebox':
      return (
        <ImageBox
          title={primary.title?.text}
          description={primary.description?.richText}
          image={primary.image}
          link={primary.link}
          link_label={primary.link_label}
        />
      );
    case 'dashboard':
      return (
        <Dashboard
          title={primary.title?.text}
          description={primary.description?.richText}
          backgroundColor={primary.background_color}
          itemsPerRow={itemsPerRow}
          items={items}
        />
      );
    case 'solutions':
      return (
        <Solutions
          generalTitle={primary.title?.text}
          subheading={primary.subheading?.richText}
          image={primary.image}
          items={items}
        />
      );
    case 'mission':
      return (
        <Mission
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          image={primary.image}
          rightText={primary.right_text?.richText}
          leftText={primary.left_text?.richText}
        />
      );
    case 'contact_form':
      return (
        <ContactFormSection
          title={primary.title?.text}
          description={primary.description?.richText}
          items={items}
          campaignId={primary.campaign_id}
        />
      );
    case 'promotions':
      return <Promotions items={items} />;
    case 'carousel':
      return <Carousel align={primary.align} items={items} />;
    case 'confirmation_message':
      return <ConfirmationMessage items={items} />;
    case 'resource_center_posts':
      return <ResourcePosts title={primary.title?.text} items={items} />;
    case 'enclosed_newsletter':
      return (
        <EnclosedNewsletterForm
          title={primary.title?.text}
          description={primary.description?.richText}
          confirmationMessage={primary.confirmation_message?.richText}
          confirmationTitle={primary.confirmation_title?.text}
          buttonLabel={primary.button_label}
          link={primary.link}
          linkLabel={primary.link_label}
          backgroundColor={primary.background_color || 'white'}
          formId={primary.form_id}
          embedCode={primary.embed_code}
        />
      );
    case 'cta_newsletter':
      return (
        <CTANewsletter
          title={primary.title?.text}
          description={primary.description?.richText}
          confirmationMessage={primary.confirmation_message?.richText}
          confirmationTitle={primary.confirmation_title?.text}
          buttonLabel={primary.button_label}
          backgroundColor={primary.background_color || 'white'}
          formId={primary.form_id}
          embedCode={primary.embed_code}
        />
      );
    case 'newsletter_form':
      return (
        <NewsletterFormSection
          title={primary.title?.text}
          description={primary.description?.richText}
          confirmationMessage={primary.confirmation_message?.richText}
          confirmationTitle={primary.confirmation_title?.text}
          buttonLabel={primary.button_label}
          legalText={primary.legal_text?.richText}
          backgroundColor={primary.background_color || 'white'}
        />
      );
    case 'comparison':
      return <Comparison tableContent={primary.table_content} />;
    case 'stat_blocks':
      return (
        <StatBlocks
          title={primary.title?.text}
          description={primary.description?.richText}
          displayBorder={primary.display_border}
          backgroundAlignment={primary.background_alignment}
          outerbackgroundColor={primary.outer_background_color}
          backgroundColor={primary.background_color}
          items={items}
        />
      );
    case 'tabbed_content':
      return (
        <TabbedContent
          title={primary.title?.text}
          description={primary.description?.richText}
          items={items}
        />
      );
    default:
      return null;
  }
};

export default renderModule;
