import React, { useEffect, useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';
import { useLocation } from '@reach/router';
import SEO from '@components/seo';
import Layout from '@layouts/default-layout';
import Wrapper from '@components/wrapper.styled';
import Text from '@components/text';
import ContactForm from '@components/contact-form';
import {
  Container,
  ContactSection,
  TextContainer,
  StyledCalendly,
  TabsContainer,
  TabContent,
  TabContentForm,
  StyledTab,
  InfoText,
} from '@components/contact-form/contact-form.styled';

interface ContactPageProps {
  edges: {
    node: {
      data: {
        page_title: {
          text: string;
        };
        meta_description?: {
          text: string;
        };
        meta_image?: {
          url: string;
          alt: string;
        };
        layout_type: 'dark' | 'light';
        title: {
          text: string;
        };
        description: {
          richText: RichTextBlock[];
        };
        campaign_id: string;
        why_contact_options: {
          option: string;
        }[];
        calendly_title: {
          text: string;
        };
        calendly_subheading: {
          richText: RichTextBlock[];
        };
        form_title: {
          text: string;
        };
        form_subheading: {
          richText: RichTextBlock[];
        };
        calendly_tab_label: string;
        form_tab_label: string;
        additional_info: {
          richText: RichTextBlock[];
        };
      };
    };
  }[];
}

interface ContactInterface {
  data: {
    prismicGeneralConfig: {
      data: {
        favicon?: {
          url?: string;
        };
      };
    };
    allPrismicContact: ContactPageProps;
  };
}

const Contact = ({ data }: ContactInterface): JSX.Element | null => {
  if (!data) return null;
  const location = useLocation();
  const origin = new URLSearchParams(location.search).get('origin') || 'contact-page';

  const {
    prismicGeneralConfig: {
      data: { favicon },
    },
    allPrismicContact,
  } = data;

  const {
    page_title,
    meta_description,
    meta_image,
    campaign_id,
    why_contact_options,
    layout_type,
    calendly_title,
    calendly_subheading,
    form_title,
    form_subheading,
    calendly_tab_label,
    form_tab_label,
    additional_info,
  } = allPrismicContact.edges[0].node.data;

  const [loaded, setLoaded] = useState(false);
  const [tabContent, setTabContent] = useState({
    title: calendly_title?.text,
    description: calendly_subheading?.richText,
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const calendlyEl = useRef(null);

  const handleTabClick = (index: number, title: string, description: RichTextBlock[]) => () => {
    if (activeIndex !== index) {
      setActiveIndex(index);
    }
    setTabContent({ title, description });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.type = 'text/javascript';
    script.async = true;
    script.addEventListener('load', () => setLoaded(true));
    document.body.appendChild(script);

    return () => {
      script.removeEventListener('load', () => setLoaded(false));
    };
  }, []);

  useEffect(() => {
    if (!loaded) return;
    window.Calendly.initInlineWidget({
      url: `https://calendly.com/d/cqx7-6c9-8vf?text_color=3d1152&primary_color=aa1ef5&utm_campaign=${origin}`,
      utm: {
        utmCampaign: origin,
      },
      parentElement: calendlyEl.current,
    });
  }, [loaded]);

  return (
    <Layout layoutType={layout_type}>
      <SEO
        title={page_title.text}
        description={meta_description?.text}
        image={meta_image}
        faviconHref={favicon?.url || ''}
      />
      <ContactSection backgroundColor="white">
        <Wrapper>
          <Container>
            <TextContainer>
              <Text fontSize="heading2" type="title" stringText={tabContent.title} />
              <Text type="subheading" richText={tabContent.description} />
            </TextContainer>
            <TabsContainer>
              <StyledTab
                type="button"
                onClick={handleTabClick(0, calendly_title?.text, calendly_subheading?.richText)}
                isActive={activeIndex === 0}
              >
                {calendly_tab_label}
              </StyledTab>
              <StyledTab
                type="button"
                onClick={handleTabClick(1, form_title?.text, form_subheading?.richText)}
                isActive={activeIndex === 1}
              >
                {form_tab_label}
              </StyledTab>
            </TabsContainer>
            <TabContent isActive={activeIndex === 0}>
              <StyledCalendly ref={calendlyEl} data-auto-load="false" />
            </TabContent>
            <TabContentForm isActive={activeIndex === 1}>
              <ContactForm why_options={why_contact_options} />
            </TabContentForm>
            <InfoText richText={additional_info.richText} fontSize="small" />
          </Container>
        </Wrapper>
      </ContactSection>
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query ContactPageQuery {
    prismicGeneralConfig {
      ...GeneralConfigFragment
    }
    allPrismicContact {
      edges {
        node {
          data {
            page_title {
              text
            }
            meta_description {
              text
            }
            meta_image {
              url
              alt
            }
            layout_type
            title {
              text
            }
            description {
              richText
            }
            campaign_id
            why_contact_options {
              option
            }
            calendly_title {
              text
            }
            calendly_subheading {
              richText
            }
            form_title {
              text
            }
            form_subheading {
              richText
            }
            calendly_tab_label
            form_tab_label
            additional_info {
              richText
            }
          }
        }
      }
    }
  }
`;
